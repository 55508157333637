import { ReactNode } from 'react';

import cn from '@/lib/cn';

type Props = {
    children?: ReactNode;
    className?: string;
};

const MaxWidthContainer = ({ children, className }: Props) => {
    return <div className={cn('mx-auto max-w-6xl px-3 sm:px-8 md:px-12', className)}>{children}</div>;
};

export default MaxWidthContainer;
