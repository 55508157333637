'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';

import cn from '@/lib/cn';

type ReadMoreProps = {
    text: string | ReactNode;
    lines: 1 | 2 | 3 | 4 | 5 | 6;
    className?: string;
    showMoreLessClassName?: string;
    // If `true`, renders a link for `Read More` / `Read Less`. If `false`, the text itself will be clickable
    useLink?: boolean;
};

const ReadMore = ({ text, lines, className, showMoreLessClassName, useLink = true }: ReadMoreProps) => {
    const [clamped, setClamped] = useState(true);
    const [showReadMoreLess, setShowReadMoreLess] = useState(true);

    const ref = useRef<HTMLSpanElement | null>(null);
    useEffect(() => {
        // Adapted from https://plainenglish.io/blog/creating-a-read-more-collapsible-component-in-reactjs-6d55fbc4ff0f
        const hasClamping = (el: HTMLElement) => {
            const { clientHeight, scrollHeight } = el;
            return clientHeight !== scrollHeight;
        };

        const checkButtonAvailability = () => {
            if (ref.current) {
                // Save current state to reapply later if necessary.
                const hadClampClass = ref.current.classList.contains('clamp');
                // Make sure that CSS clamping is applied if applicable.
                if (!hadClampClass) ref.current.classList.add('clamp');
                // Check for clamping and show or hide button accordingly.
                setShowReadMoreLess(hasClamping(ref.current));
                // Sync clamping with local state.
                if (!hadClampClass) ref.current.classList.remove('clamp');
            }
        };

        checkButtonAvailability();
        window.addEventListener('resize', checkButtonAvailability);

        return () => {
            window.removeEventListener('resize', checkButtonAvailability);
        };
    }, [ref]);

    return (
        <>
            <span
                className={cn(className, clamped && `line-clamp-${lines}`)}
                ref={ref}
                onClick={!useLink && showReadMoreLess ? () => setClamped(!clamped) : undefined}
            >
                {text}
            </span>
            {useLink && showReadMoreLess && (
                <a
                    onClick={() => setClamped(!clamped)}
                    className={cn('text-brand-navy no-underline block mt-2', showMoreLessClassName)}
                >
                    Read {clamped ? 'more' : 'less'}
                </a>
            )}
        </>
    );
};

export default ReadMore;
